import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import { domainNameWithPunycode } from '../formatters';
import { StatsPageFigure } from '../components';
import { theme } from '../../../theme';
import { Small, Large } from '../../text';

const Top9LockupsQuery = graphql`
  query Top9LockupsQuery {
    dataTop10: contentfulStatsPageRawData {
      ...Top9LockupsData
    }
  }
`;

const Top9Lockups = () => {
  const { dataTop10 } = useStaticQuery(Top9LockupsQuery);

  const top9LockupsAllTime = dataTop10.allTime.slice(0, 9);
  const highestLockupAmount = top9LockupsAllTime[0].amount;

  return (
    <React.Fragment>
      <StatsPageFigure
        width="100%"
        legend={[
          { color: theme.color.white, label: "Lockup" },
          { color: theme.color.nbPrimary, label: "Bid" },
          { color: theme.color.yellowLight, label: "Blind" },
        ]}
        caption="Top 9 lockups all time"
        captionProps={{
          style: {
            fontSize: theme.text.medium.size,
            lineHeight: theme.text.medium.lineHeight,
          }
        }}>
        <Row>
          {top9LockupsAllTime.slice(0, 3).map(data => (
            <LockupFigure {...data} highestLockupAmount={highestLockupAmount} />
          ))}
        </Row>
        <Row>
          {top9LockupsAllTime.slice(3, 6).map(data => (
            <LockupFigure {...data} highestLockupAmount={highestLockupAmount} />
          ))}
        </Row>
        <Row>
          {top9LockupsAllTime.slice(6, 9).map(data => (
            <LockupFigure {...data} highestLockupAmount={highestLockupAmount} />
          ))}
        </Row>
      </StatsPageFigure>
    </React.Fragment>
  )
};

const LockupFigure = ({
  amount: lockupAmount,
  amount2: bidAmount,
  domainName,
  highestLockupAmount,
}) => {
  const formatAmount = (amount) => {
    return parseFloat(amount).toLocaleString(undefined, { minimumFractionDigits: 2 });
  }

  const formattedAmount = formatAmount(lockupAmount);
  const formattedBidAmount = formatAmount(bidAmount);

  const blindAmount = lockupAmount - bidAmount;
  const formattedBlindAmount = formatAmount(blindAmount);

  return (
    <Figure>
      <Large weight="medium" colored="white">
        {domainNameWithPunycode(domainName)}
      </Large>
      <GraphArea>
        <GraphCircle
          colored="yellowLight"
          widthPercent={`${lockupAmount / highestLockupAmount * 100}%`} />
        <GraphCircle
          colored="nbPrimary"
          widthPercent={`${bidAmount / highestLockupAmount * 100}%`} />
      </GraphArea>
      <figcaption>
        <Large weight="medium" colored="white">{formattedAmount}</Large>
        <div>
          <Small
            as="span"
            weight="medium"
            colored="nbPrimary">
            {formattedBidAmount}
          </Small>
          <Small
            as="span"
            style={{ marginLeft: '8px', marginRight: '8px' }}
            weight="medium"
            colored="white">
            +
          </Small>
          <Small
            as="span"
            weight="medium"
            colored="yellowLight">
            {formattedBlindAmount}
          </Small>
        </div>
      </figcaption>
    </Figure>
  )
}

const Figure = styled.figure`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: column;

  figcaption {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  & > * + * {
    margin-top: ${({ theme }) => theme.spacing(3)};
  }
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  padding-top: ${({ theme }) => theme.spacing(5)};
  padding-left: ${({ theme }) => theme.spacing(3.5)};
  padding-right: ${({ theme }) => theme.spacing(3.5)};
  border-bottom: 1px solid ${({ theme }) => theme.color.dmGrey30};
  padding-bottom: ${({ theme }) => theme.spacing(5)};
  justify-content: space-between;

  @media ${({ theme }) => theme.breakpoints.sm} {
    border-bottom: none;
    flex-direction: column;

    & > * + * {
      margin-top: ${({ theme }) => theme.spacing(14)};
    }
  }
`;

const GraphArea = styled.div`
  width: ${({ theme }) => theme.spacing(25)};
  height: ${({ theme }) => theme.spacing(25)};
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: center;
`;

const GraphCircle = styled.div`
  width: ${({ widthPercent }) => widthPercent};
  height: ${({ widthPercent }) => widthPercent};
  position: absolute;
  min-width: 3px;
  min-height: 3px;
  border-radius: 50%;
  background-color: ${({ colored }) => theme.color[colored]};
`;

export { Top9Lockups };