import React from 'react';

import { Backdrop, StackText, SectionLinks, SectionLink } from './Hero.StyledComponents.js';
import { DisplayTiny, Tiny, Regular } from '../../text';
import { SECTIONS } from '../constants';

const StatsPageHero = () => (
  <Backdrop>
    <StackText>
      <DisplayTiny colored="white" align="center">
        Handshake Statistics
      </DisplayTiny>
      <SectionLinks>
        <SectionLink colored="white" href={SECTIONS.AUCTIONS.href}>
          {SECTIONS.AUCTIONS.title}
        </SectionLink>
        <Regular colored="white">|</Regular>
        <SectionLink colored="white" href={SECTIONS.MARKETPLACE.href}>
          {SECTIONS.MARKETPLACE.title}
        </SectionLink>
        <Regular colored="white">|</Regular>
        <SectionLink colored="white" href={SECTIONS.REGISTRY.href}>
          {SECTIONS.REGISTRY.title}
        </SectionLink>
        <Regular colored="white">|</Regular>
        <SectionLink colored="white" href={SECTIONS.USAGE.href}>
          {SECTIONS.USAGE.title}
        </SectionLink>
      </SectionLinks>
      <Tiny colored="dmGrey50" align="center">
        updated hourly
      </Tiny>
    </StackText>
  </Backdrop>
);

export { StatsPageHero };
