import { css } from 'styled-components';
import { theme } from '../../theme';

class Section {
  constructor(title) {
    this.title = title;
    this.anchor = title.toLowerCase().replace(' ', '-');
    this.href = `#${this.anchor}`;
  }
}

const SECTIONS = Object.freeze({
  USAGE: new Section('Usage'),
  AUCTIONS: new Section('Auctions'),
  MARKETPLACE: new Section('Marketplace'),
  REGISTRY: new Section('NB Registry'),
});

const LIST_FIGURE_SPACING_CSS = css`
  max-width: ${({ theme }) => theme.spacing(54)}; /* 432px */

  & > * + * {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;

const LIST_TWO_COLUMN_CSS = css`
  width: 880px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 50%);
  grid-template-rows: repeat(10, 1fr); /* 5 rows */
  grid-auto-flow: column;
  list-style: none;
  padding: 0;
  gap: 15px 100px;

  li {
    width: 100%;
    padding: 0.15em;
    margin: 0;
  }
`;

const LIST_FIGURE_MAX_WIDTH_CSS = css`
  @media ${({ theme }) => theme.breakpoints.sm} {
    margin: 0 auto;
    max-width: ${({ theme }) => theme.spacing(54)}; /* 432px */
  }
`;

const CHART_AXIS_BASE_CONFIG_LIGHT = {
  grid: {
    line: {
      style: {
        stroke: theme.color.grey30,
        lineWidth: 1,
      },
    },
  },
  line: {
    style: {
      stroke: theme.color.grey30,
      lineWidth: 1,
    },
  },
  label: {
    offset: 16,
    style: {
      fill: theme.color.brandDark,
      fontSize: 12,
      fontFamily: 'Roboto',
      fontWeight: 500,
    },
  },
  tickLine: {
    style: {
      stroke: theme.color.grey30,
      lineWidth: 1,
    },
    alignTick: true,
  },
};

const CHART_AXIS_BASE_CONFIG_DARK = {
  grid: {
    line: {
      style: {
        stroke: theme.color.dmGrey30,
        lineWidth: 1,
      },
    },
  },
  label: {
    offset: 16,
    style: {
      fill: theme.color.white,
      fontSize: 12,
      fontFamily: 'Roboto',
      fontWeight: 500,
    },
  },
  line: {
    style: {
      stroke: theme.color.dmGrey30,
      lineWidth: 1,
    },
  },
  tickLine: {
    style: {
      stroke: theme.color.dmGrey30,
      lineWidth: 1,
    },
    alignTick: true,
  },
};

export {
  SECTIONS,
  LIST_FIGURE_SPACING_CSS,
  LIST_TWO_COLUMN_CSS,
  LIST_FIGURE_MAX_WIDTH_CSS,
  CHART_AXIS_BASE_CONFIG_DARK,
  CHART_AXIS_BASE_CONFIG_LIGHT,
};
