import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { Chart } from '@antv/g2';

import { Tiny } from '../../text';
import { StatsPageFigure } from '../components';
import { theme } from '../../../theme';
import { domainNameWithPunycode, domainNameWithoutPunycode, abbreviatedLocaleStringWithDigits } from '../formatters';
import { CHART_AXIS_BASE_CONFIG_LIGHT } from '../constants';

const Top10MarketplaceSalesQuery = graphql`
  query Top10MarketplaceSalesQuery {
    dataTop10: contentfulStatsPageRawData {
      ...Top10MarketplaceSalesData
    }
  }
`;

const Top10MarketplaceSales = () => {
  const { dataTop10 } = useStaticQuery(Top10MarketplaceSalesQuery);

  const chartRef = React.useRef();
  React.useLayoutEffect(() => {
    if (!dataTop10 || !chartRef || !chartRef.current) return;

    const formattedData = dataTop10.allTime.reduce((formattedData, dataPoint) => {
      formattedData.push({
        stage: "originalAuctionPrice",
        price: parseInt(dataPoint.amount2),
        domain: dataPoint.domainName,
      });
      formattedData.push({
        stage: "salePrice",
        price: parseInt(dataPoint.amount) - parseInt(dataPoint.amount2),
        domain: dataPoint.domainName,
        _originalAuctionPrice: parseInt(dataPoint.amount2),
      });
      return formattedData.slice();
    }, []);
    const maxSalePrice = formattedData.reduce((max, current) => {
      if (current.price > max) return current.price;
      return max;
    }, 0);

    const chart = new Chart({
      height: 650,
      autoFit: true,
      container: chartRef.current
    });
    chart.data(formattedData);
    chart.axis('price', {
      ...CHART_AXIS_BASE_CONFIG_LIGHT,
      label: {
        formatter: abbreviatedLocaleStringWithDigits,
        ...CHART_AXIS_BASE_CONFIG_LIGHT.label,
      }
    });
    chart.axis('domain', {
      ...CHART_AXIS_BASE_CONFIG_LIGHT,
      grid: null,
      label: {
        formatter: domainNameWithoutPunycode,
        ...CHART_AXIS_BASE_CONFIG_LIGHT.label,
      }
    });
    chart.scale('domain', {
      range: [0.925, 0.075],
    })
    chart.scale('price', {
      min: 0,
      max: maxSalePrice,
      type: 'pow',
      tickCount: 4,
    })
    chart.legend(false);
    chart.tooltip({
      title: (originalValue) => domainNameWithPunycode(originalValue),
      shared: true,
      customItems: (items) => items.map(formatTooltipItem),
      showMarkers: false,
    });
    chart
      .interval()
      .adjust('stack')
      .position('domain*price')
      .size(48)
      .color('stage*domain', (stage, domain) => {
        if (stage === 'originalAuctionPrice') return theme.color.nbPrimaryLight;
        if (stage === 'salePrice') return theme.color.proBrand;
      });
    chart.coordinate('rect').transpose();
    chart.interaction('active-region');
    chart.render();

    return () => chart.destroy();
  }, [dataTop10, chartRef]);

  return (
    <React.Fragment>
      <StatsPageFigure
        width="100%"
        legend={[
          { color: theme.color.nbPrimaryLight, label: "Original auction price" },
          { color: theme.color.greenLight, label: "Sale price" },
        ]}
        caption="Top 10 marketplace sales all time"
        captionProps={{
          colored: 'brandDark',
          style: {
            fontSize: theme.text.medium.size,
            lineHeight: theme.text.medium.lineHeight,
          }
        }}>
        <Tiny
          css={{
            paddingTop: theme.spacing(2),
            marginBottom: theme.spacing(1)
          }}
          weight="medium"
          colored="grey70">
          Domain
        </Tiny>
        <ChartContainer ref={chartRef} heightPx={650} />
        <Tiny
          css={{
            width: '50%',
            transform: "translateX(100%)",
            marginTop: theme.spacing(2)
          }}
          weight="medium"
          colored="grey70">
          Price in HNS
        </Tiny>
      </StatsPageFigure>
    </React.Fragment>
  )
};

const ChartContainer = styled.div`
  height: ${({ heightPx }) => heightPx}px;
  min-height: ${({ heightPx }) => heightPx}px;
`;

const formatTooltipItem = (item) => {
  const formattedItem = Object.assign({}, item);

  if (formattedItem["name"] === "salePrice") {
    const trueSalePrice = formattedItem["data"]["price"] + formattedItem["data"]["_originalAuctionPrice"];
    formattedItem["value"] = `${parseInt(trueSalePrice).toLocaleString()} HNS`;
  } else {
    formattedItem["value"] = `${parseInt(formattedItem["value"]).toLocaleString()} HNS`;
  }

  if (formattedItem["name"] === "salePrice") formattedItem["name"] = "Sale price";
  if (formattedItem["name"] === "originalAuctionPrice") formattedItem["name"] = "Original auction price";

  return formattedItem;
}

export { Top10MarketplaceSales };