import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { Chart } from '@antv/g2';

import { Tiny } from '../../text';
import { StatsPageFigure } from '../components';
import { theme } from '../../../theme';
import { CHART_AXIS_BASE_CONFIG_LIGHT } from '../constants';
import { abbreviatedLocaleStringWithDigits, dateMmmYy } from '../formatters';

const MarketplaceVolumeQuery = graphql`
  query MarketplaceVolumeQuery {
    dataAllTime: contentfulStatsPageRawData {
      ...MarketplaceVolumeData
    }
  }
`;

const MarketplaceVolume = () => {
  const { dataAllTime } = useStaticQuery(MarketplaceVolumeQuery);

  const chartRef = React.useRef();
  React.useLayoutEffect(() => {
    if (!dataAllTime || !chartRef || !chartRef.current) return;

    const dataLength = dataAllTime.monthlyMarketplaceVolume.length - 1;
    const formattedData = dataAllTime.monthlyMarketplaceVolume.map((dataPoint, i) => ({
      hns: parseInt(dataPoint.value),
      date: dataPoint.date,
    }))

    const chart = new Chart({
      height: 450,
      autoFit: true,
      container: chartRef.current,
      appendPadding: [0, 0, 4, 0]
    });
    chart.data(formattedData);
    chart.axis('hns', {
      ...CHART_AXIS_BASE_CONFIG_LIGHT,
      line: null,
      label: {
        formatter: abbreviatedLocaleStringWithDigits,
        ...CHART_AXIS_BASE_CONFIG_LIGHT.label,
      }
    });
    chart.axis('date', {
      ...CHART_AXIS_BASE_CONFIG_LIGHT,
      grid: null,
      label: {
        formatter: (originalValue, _, index) => {
          if (index === 0 || index === dataLength) return dateMmmYy(originalValue);
          return dateMmmYy(originalValue).split(' ')[0];
        },
        ...CHART_AXIS_BASE_CONFIG_LIGHT.label,
      },
    });
    chart.scale('hns', {
      tickInterval: 200000,
    })
    chart.theme({ columnWidthRatio: 0.8 });
    chart.tooltip({
      title: (originalValue) => dateMmmYy(originalValue, true),
      customItems: (items) => items.map(formatTooltipItem),
      showMarkers: false,
    });
    chart.interaction('element-highlight');
    chart.interval().position('date*hns').color(theme.color.greenLight);
    chart.render()

    return () => chart.destroy();
  }, [dataAllTime, chartRef]);

  return (
    <React.Fragment>
      <StatsPageFigure
        width="100%"
        caption="Monthly Namebase marketplace volume"
        captionProps={{
          colored: 'brandDark',
          style: {
            fontSize: theme.text.medium.size,
            lineHeight: theme.text.medium.lineHeight,
          }          
        }}>
        <Tiny
          css={{
            paddingTop: theme.spacing(2),
            marginBottom: theme.spacing(1)
          }}
          weight="medium"
          colored="grey70">
          HNS
        </Tiny>
        <ChartContainer ref={chartRef} heightPx={450} />
      </StatsPageFigure>
    </React.Fragment>
  )
};

const ChartContainer = styled.div`
  height: ${({ heightPx }) => heightPx}px;
  min-height: ${({ heightPx }) => heightPx}px;
`;

const formatTooltipItem = (item) => {
  const formattedItem = Object.assign({}, item);

  formattedItem["name"] = "HNS";
  formattedItem["value"] = parseInt(formattedItem["value"]).toLocaleString();

  return formattedItem;
}

export { MarketplaceVolume };