import React from 'react';
import styled from 'styled-components';

const StatsPageSubSection = ({ children, ...props }) => (
  <Layout {...props}>
    <Content>
      {children}
    </Content>
  </Layout>
);

const Layout = styled.div`
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing(5)};
  padding-bottom: ${({ theme, zeroPaddingBottom = false }) => theme.spacing(zeroPaddingBottom ? 0 : 6)};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const Content = styled.div`
  width: 880px;
  margin: 0 auto;
  display: flex;
  max-width: 100%;
  justify-content: space-between;

  @media ${({ theme }) => theme.breakpoints.sm} {
    flex-direction: column;
    & > * + * {
      margin-top: ${({ theme }) => theme.spacing(8)} !important;
    }
  }
`;

export { StatsPageSubSection };