import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Small, Tiny } from '../../text';
import styled from 'styled-components';

import { Label, Name, StatsPageFigure } from '../components';
import { LIST_TWO_COLUMN_CSS, LIST_FIGURE_MAX_WIDTH_CSS } from '../constants';
import { domainNameWithPunycode } from '../formatters';

const RegistryLeaderboardQuery = graphql`
  query RegistryLeaderboardQuery {
    registryLeaderboard: allContentfulStatsPageRawDataRegistryLeaderboardJsonNode {
      items: nodes {
        rank
        domain
        registrations
      }
    }
  }
`;

const RegistryLeaderboard = () => {
  const { registryLeaderboard } = useStaticQuery(RegistryLeaderboardQuery);

  return (
    <React.Fragment>
      <StatsPageFigure
        width="100%"
        caption="Leaderboard - Top 20 TLDs"
        css={LIST_FIGURE_MAX_WIDTH_CSS}>
        <ol css={LIST_TWO_COLUMN_CSS}>
          {registryLeaderboard.items.map((item, i) => (
            <li
              style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
              key={`${item.domain}${i}`}>
              <Label>
                <Tiny colored="white">{String(item.rank).padStart(2, '0')}</Tiny>
                <Name href={`/domains/${item.domain}`}>{domainNameWithPunycode(item.domain)}</Name>
              </Label>
              <Tiny colored="white" weight="medium">
                {parseFloat(item.registrations).toLocaleString()}
              </Tiny>
            </li>
          ))}
        </ol>
        <Centered>
          <Small as="a" href="/registry/leaderboard" colored={'proBrand'}>
            See full TLD Leaderboard
          </Small>
        </Centered>
      </StatsPageFigure>
    </React.Fragment>
  );
};

const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
`;

export { RegistryLeaderboard };
