import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import { Backdrop } from './StillInterestedCta.StyledComponents';
import { Medium, Small } from '../../text';

const StillInterestedCtaQuery = graphql`
  query StillInterestedCtaQuery {
    content: contentfulStatsPageContent {
      ...StillInterestedCtaContent
    }
  }
`;

const StillInterestedCta = () => {
  const { content } = useStaticQuery(StillInterestedCtaQuery);
  return (
    <Backdrop>
      <Medium colored="white">{content.stillInterestedCtaTitle}</Medium>
      <Small
        as="a"
        rel="noopener noreferrer"
        href={content.stillInterestedLinkHref}
        target="_blank"
        colored={"proBrand"}
      >
        {content.stillInterestedLinkTitle}
      </Small>
    </Backdrop>
  )
};

export { StillInterestedCta }