import styled from 'styled-components';
import { Medium, Small } from '../../text';

export const Backdrop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing(6)};
  padding-bottom: ${({ theme }) => theme.spacing(6)};
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.dmGrey20};

  & > * + * {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }

  ${Medium} {
    font-family: 'Roboto Mono';
  }

  ${Small} {
    transition: ${({ theme }) => theme.transitions.color};
    &:hover {
      color: ${({ theme }) => theme.color.proBrandDark};
    }
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    ${Medium} {
      font-size: ${({ theme }) => theme.text.regular.size};
      line-height: ${({ theme }) => theme.text.regular.lineHeight};
    }
  }
`;