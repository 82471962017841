import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { LIST_FIGURE_SPACING_CSS, LIST_FIGURE_MAX_WIDTH_CSS } from '../constants';
import { StatsPageListItem, StatsPageFigure } from '../components';

const Top10SalesListsQuery = graphql`
  query Top10SalesListsQuery {
    dataTop10: contentfulStatsPageRawData {
      ...Top10SalesListsData
    }
  }
`;

const Top10SalesLists = () => {
  const { dataTop10 } = useStaticQuery(Top10SalesListsQuery);
  return (
    <React.Fragment>
      <StatsPageFigure caption="Top 10 sales last month" css={LIST_FIGURE_MAX_WIDTH_CSS}>
        <ol css={LIST_FIGURE_SPACING_CSS}>
          {dataTop10.lastMonth.map((dataPoint, i) => (
            <StatsPageListItem key={`${dataPoint.domainName}${i}`} index={i} {...dataPoint} />
          ))}
        </ol>
      </StatsPageFigure>
      <StatsPageFigure caption="Top 10 sales last 24 hours" css={LIST_FIGURE_MAX_WIDTH_CSS}>
        <ol css={LIST_FIGURE_SPACING_CSS}>
          {dataTop10.last24Hours.map((dataPoint, i) => (
            <StatsPageListItem key={`${dataPoint.domainName}${i}`} index={i} {...dataPoint} />
          ))}
        </ol>
      </StatsPageFigure>
    </React.Fragment>
  )
};

export { Top10SalesLists };