import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { Chart  } from '@antv/g2';

import { Tiny } from '../../text';
import { StatsPageFigure } from '../components';
import { theme } from '../../../theme';
import { domainNameWithoutPunycode, abbreviatedLocaleStringWithDigits, domainNameWithPunycode } from '../formatters';
import { CHART_AXIS_BASE_CONFIG_LIGHT } from '../constants';

const Top10AuctionsQuery = graphql`
  query Top10AuctionsQuery {
    dataTop10: contentfulStatsPageRawData {
      ...Top10AuctionsData
    }
  }
`;

const Top10Auctions = () => {
  const { dataTop10 } = useStaticQuery(Top10AuctionsQuery);

  const chartRef = React.useRef();
  React.useLayoutEffect(() => {
    if (!dataTop10 || !chartRef || !chartRef.current) return;

    const formattedData = dataTop10.allTime.map((dataPoint) => ({
      price: parseInt(dataPoint.amount),
      domain: dataPoint.domainName,
    }))

    const chart = new Chart({
      height: 650,
      autoFit: true,
      container: chartRef.current
    });
    chart.data(formattedData);
    chart.axis('price', {
      ...CHART_AXIS_BASE_CONFIG_LIGHT,
      label: {
        formatter: abbreviatedLocaleStringWithDigits,
        ...CHART_AXIS_BASE_CONFIG_LIGHT.label,
      }
    });
    chart.axis('domain', {
      ...CHART_AXIS_BASE_CONFIG_LIGHT,
      grid: null,
      label: {
        formatter: domainNameWithoutPunycode,
        ...CHART_AXIS_BASE_CONFIG_LIGHT.label,
      }
    });
    chart.scale('domain', {
      range: [0.925, 0.075],
    })
    chart.scale('price', {
      tickInterval: 50000,
    })
    chart.tooltip({
      title: (originalValue) => domainNameWithPunycode(originalValue),
      customItems: (items) => items.map(formatTooltipItem),
      showMarkers: false,
    });
    chart
      .interval()
      .position('domain*price')
      .size(48)
      .color(theme.color.nbPrimary);
    chart.coordinate().transpose();
    chart.interaction('element-highlight');
    chart.render();

    return () => chart.destroy();
  }, [dataTop10, chartRef]);

  return (
    <React.Fragment>
      <StatsPageFigure
        width="100%"
        caption="Top 10 auctions all time by price paid"
        captionProps={{
          colored: 'brandDark',
          style: {
            fontSize: theme.text.medium.size,
            lineHeight: theme.text.medium.lineHeight,
          }
        }}>
        <Tiny
          css={{
            paddingTop: theme.spacing(2),
            marginBottom: theme.spacing(1)
          }}
          weight="medium"
          colored="grey70">
          Domain
        </Tiny>
        <ChartContainer ref={chartRef} heightPx={650} />
        <Tiny
          css={{
            width: '50%',
            transform: "translateX(100%)",
            marginTop: theme.spacing(2)
          }}
          weight="medium"
          colored="grey70">
          Price in HNS
        </Tiny>
      </StatsPageFigure>
    </React.Fragment>
  )
};

const ChartContainer = styled.div`
  height: ${({ heightPx }) => heightPx}px;
  min-height: ${({ heightPx }) => heightPx}px;
`;

const formatTooltipItem = (item) => {
  const formattedItem = Object.assign({}, item);

  formattedItem["name"] = "Price";
  formattedItem["value"] = `${parseInt(formattedItem["value"]).toLocaleString()} HNS`;

  return formattedItem;
}

export { Top10Auctions };