import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { Chart  } from '@antv/g2';

import { theme } from '../../../theme';
import { Tiny } from '../../text';
import { StatsPageFigure } from '../components';
import { abbreviatedLocaleStringWithDigits, dateMmmYy } from '../formatters';
import { CHART_AXIS_BASE_CONFIG_DARK } from '../constants';

const HandshakeUsageQuery = graphql`
  query HandshakeUsageQuery {
    dataAllTime: contentfulStatsPageRawData {
      ...HandshakeUsageData
    }
  }
`;

const HandshakeUsage = () => {
  const { dataAllTime } = useStaticQuery(HandshakeUsageQuery);

  const uniqueNamesInUseChartRef = React.useRef(); 
  const registeredNamesOverTimeChartRef = React.useRef(); 

  React.useLayoutEffect(() => {
    if (
      !dataAllTime ||
      !registeredNamesOverTimeChartRef ||
      !registeredNamesOverTimeChartRef.current
    ) {
      return;
    }

    const formattedData = dataAllTime.registeredNames.map((dataPoint) => ({
      count: parseInt(dataPoint.value),
      date: dataPoint.date,
    }))

    const chart = new Chart({
      height: 350,
      autoFit: true,
      container: registeredNamesOverTimeChartRef.current,
      appendPadding: [0, 0, 4, 0]
    });
    chart.data(formattedData);
    chart.axis('count', {
      ...CHART_AXIS_BASE_CONFIG_DARK,
      line: null,
      label: {
        formatter: abbreviatedLocaleStringWithDigits,
        ...CHART_AXIS_BASE_CONFIG_DARK.label,
      }
    });
    chart.axis('date', {
      ...CHART_AXIS_BASE_CONFIG_DARK,
      grid: null,
      label: {
        formatter: dateMmmYy,
        ...CHART_AXIS_BASE_CONFIG_DARK.label,
      }
    });
    chart.scale('count', {
      min: 0,
      nice: true,
    });
    chart.scale('date', {
      tickCount: 2,
    });
    chart.tooltip({
      title: (originalValue) => dateMmmYy(originalValue, true),
      shared: true,
      customItems: (items) => items.map(formatTooltipItem),
      showCrosshairs: true,
    });
    chart
      .line()
      .position('date*count')
      .color(theme.color.proBrand)
      .size(1);
    chart
      .point()
      .position('date*count')
      .style({
        fill: theme.color.proBrand,
        stroke: theme.color.proBrand
      })
      .size(3);
    chart.render();

    return () => chart.destroy();
  }, [dataAllTime, registeredNamesOverTimeChartRef]);

  React.useLayoutEffect(() => {
    if (
      !dataAllTime ||
      !uniqueNamesInUseChartRef ||
      !uniqueNamesInUseChartRef.current
    ) {
      return;
    }

    const formattedData = dataAllTime.uniqueNamesInUse.map((dataPoint) => ({
      count: parseInt(dataPoint.value),
      date: dataPoint.date,
    }))

    const chart = new Chart({
      height: 350,
      autoFit: true,
      container: uniqueNamesInUseChartRef.current,
      appendPadding: [0, 0, 4, 0]
    });
    chart.data(formattedData);
    chart.axis('count', {
      ...CHART_AXIS_BASE_CONFIG_DARK,
      line: null,
      label: {
        formatter: abbreviatedLocaleStringWithDigits,
        ...CHART_AXIS_BASE_CONFIG_DARK.label,
      }
    });
    chart.axis('date', {
      ...CHART_AXIS_BASE_CONFIG_DARK,
      grid: null,
      label: {
        formatter: dateMmmYy,
        ...CHART_AXIS_BASE_CONFIG_DARK.label,
      }
    });
    chart.scale('count', {
      min: 0,
      nice: true,
    });
    chart.scale('date', {
      tickCount: 2,
    });
    chart.tooltip({
      title: (originalValue) => dateMmmYy(originalValue, true),
      shared: true,
      customItems: (items) => items.map(formatTooltipItem),
      showCrosshairs: true,
    });
    chart
      .line()
      .position('date*count')
      .color(theme.color.proBrand)
      .size(1);
    chart
      .point()
      .position('date*count')
      .style({
        fill: theme.color.proBrand,
        stroke: theme.color.proBrand
      })
      .size(3);
    chart.render();

    return () => chart.destroy();
  }, [dataAllTime, uniqueNamesInUseChartRef]);

  return (
    <React.Fragment>
      <StatsPageFigure width="47%" caption="Registered names over time">
        <Tiny
          css={{ marginBottom: theme.spacing(2) }}
          weight="medium"
          colored="white">
          Count
        </Tiny>
        <ChartContainer ref={registeredNamesOverTimeChartRef} heightPx={350} />
      </StatsPageFigure>
      <StatsPageFigure width="47%" caption="Unique Handshake domains in use">
        <Tiny
          css={{ marginBottom: theme.spacing(2) }}
          weight="medium"
          colored="white">
          Count
        </Tiny>
        <ChartContainer ref={uniqueNamesInUseChartRef} heightPx={350} />
      </StatsPageFigure>
    </React.Fragment>
  )
};

const ChartContainer = styled.div`
  height: ${({ heightPx }) => heightPx}px;
  min-height: ${({ heightPx }) => heightPx}px;
`;

const formatTooltipItem = (item) => {
  const formattedItem = Object.assign({}, item);

  formattedItem["name"] = "Count";
  formattedItem["value"] = parseInt(formattedItem["value"]).toLocaleString();

  return formattedItem;
}

export { HandshakeUsage };