import React from 'react';
import styled from 'styled-components';

import { domainNameWithPunycode } from '../formatters';
import { Tiny } from '../../text';

const StatsPageListItem = ({ index, amount, domainName }) => (
  <li style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
    <Label>
      <Tiny colored="white">{String(index + 1).padStart(2, '0')}</Tiny>
      <Name href={`/domains/${domainName}`}>{domainNameWithPunycode(domainName)}</Name>
    </Label>
    <Tiny colored="white" weight="medium">
      {parseFloat(amount).toLocaleString(undefined, { minimumFractionDigits: 2 })} HNS
    </Tiny>
  </li>
);

export const Name = styled(Tiny).attrs(props => ({
  as: 'a',
  rel: 'noopener noreferrer',
  target: '_blank',
  colored: 'white',
}))`
  transition: ${({ theme }) => theme.transitions.color};

  &:hover {
    color: ${({ theme }) => theme.color.greenLight};
  }
`;

export const Label = styled.div`
  display: flex;

  * {
    font-family: 'Roboto Mono';
  }

  & > * {
    margin-right: ${({ theme }) => theme.spacing(2)};
  }
`;

export { StatsPageListItem };
