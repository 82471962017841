import React from 'react';
import styled from 'styled-components';

import { Tiny, Small } from '../../text';

const StatsPageFigure = ({
  width = "45%",
  legend,
  caption,
  children,
  captionProps,
  ...props
}) => (
  <Figure width={width} {...props}>
    <FigCaption>
      <Small weight="medium" colored="white" {...captionProps}>
        {caption}
      </Small>
      {legend ?
        <DescriptionList>
          {legend.map(({ color, label }, i) => (
            <div key={i}>
              <DescriptionTerm color={color} />
              <DescriptionData color={color}>{label}</DescriptionData>
            </div>
          ))}
        </DescriptionList>
        : null}
    </FigCaption>
    <div css={{ width: '100%' }}>
      {children}
    </div>
  </Figure>
);

const FigCaption = styled.figcaption`
  display: flex;
  align-items: baseline;

  &:first-child {
    font-family: 'Roboto Mono';
    margin-bottom: ${({ theme }) => theme.spacing(3)};
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    flex-direction: column;
  }
`;

const Figure = styled.figure`
  width: ${({ width }) => width};
  margin: 0;
  padding: 0;
  max-width: 100%;

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 100%;
  }
`;

const DescriptionList = styled.dl`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing(4)};

  div {
    display: flex;
    align-items: center;
  }

  & > * + * {
    margin-left: ${({ theme }) => theme.spacing(4)};
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    margin-top: ${({ theme }) => theme.spacing(2)};
    margin-left: 0;
  }
`;

const DescriptionTerm = styled.dt`
  width: ${({ theme }) => theme.spacing(1)};
  height: ${({ theme }) => theme.spacing(1)};
  margin-right: ${({ theme }) => theme.spacing(1)};
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

const DescriptionData = styled(Tiny).attrs(props => ({ as: 'dd', weight: 'medium' }))`
  color: ${({ color }) => color};
  height: max-content;
  font-family: Roboto;
`;

export { StatsPageFigure }