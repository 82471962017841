/**
 * debounceWindowEvent.js
 *
 * Use me when you need to perform actions in response to
 * a window event.
 *
 * It will ensure your fn callback only runs once a frame
 */
const debounceWindowEventCallback = function(fn) {
  let timer;

  return function() {
    let context = this;
    let args = arguments;

    if (timer) window.cancelAnimationFrame(timer);

    timer = window.requestAnimationFrame(function() {
      fn.apply(context, args);
    });
  };
};

export { debounceWindowEventCallback };
