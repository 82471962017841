import React from 'react';

import Layout from '../components/layout';
import { StatsPage } from '../components/StatsPage';
import { navColorOptions, navColors } from '../components/navbar/navColors';
import SEO from '../components/seo';
import { usePageMetaAttributes } from '../utils/usePageMetaAttributes';

const Stats = () => {
  const meta = usePageMetaAttributes('stats');
  const [scrolledToTop, setScrolledToTop] = React.useState(true);

  // Used by StatsPage component to control the position of the navbar based on page scroll position
  const navbarRef = React.useRef(null);

  React.useEffect(() => {
    if (typeof window !== 'undefined') document.body.style.background = navColors[navColorOptions.dramatic].background;
    return () => {
      if (typeof window !== 'undefined') document.body.style.background = 'initial';
    };
  }, []);

  React.useLayoutEffect(() => {
    if (typeof window !== 'undefined' && document.location.hash) {
      document.querySelector(document.location.hash).scrollIntoView({ block: 'start' });
    }
  }, []);

  return (
    <Layout
      noFooter // Footer rendered by StatsPage component
      navColor={navColorOptions.dramatic}
      navbarRef={navbarRef}
      onScrolledToTopChange={setScrolledToTop}>
      <SEO {...meta} />
      <StatsPage navbarRef={navbarRef} scrolledToTop={scrolledToTop} />
    </Layout>
  );
};

export default Stats;
