import React from 'react';
import styled from 'styled-components';

import { Huge } from '../../text';

const StatsPageSection = ({ title, anchor, children }) => (
  <Layout id={anchor} name={anchor}>
    <Title>
      <Huge>{title}</Huge>
    </Title>
    {children}
  </Layout>
);

const Layout = styled.section`
  width: 100%;
  & > * {
    padding-left: ${({ theme }) => theme.spacing(6)};
    padding-right: ${({ theme }) => theme.spacing(6)};
  }

  @media ${props => props.theme.breakpoints.sm} {
    & > * {
      padding-left: ${({ theme }) => theme.spacing(2)};
      padding-right: ${({ theme }) => theme.spacing(2)};
    }
  }
`;

const Title = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.color.white};
  border-top: 1px solid ${({ theme }) => theme.color.brandDark};
  padding-top: ${({ theme }) => theme.spacing(2)};
  border-bottom: 1px solid ${({ theme }) => theme.color.brandDark};
  padding-bottom: ${({ theme }) => theme.spacing(1)};

  ${Huge} {
    width: 880px;
    margin: 0 auto;
    max-width: 100%;
  }
`;

export { StatsPageSection };