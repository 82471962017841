import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { Chart } from '@antv/g2';

import { Tiny } from '../../text';
import { theme } from '../../../theme';
import { StatsPageFigure } from '../components';
import { CHART_AXIS_BASE_CONFIG_LIGHT } from '../constants';
import { abbreviatedLocaleString, dateMmmYy, dateMmmDdYyyy } from '../formatters';

const BidVolumeQuery = graphql`
  query BidVolumeQuery {
    data: contentfulStatsPageRawData {
      ...BidVolumeData
    }
  }
`;

const FIVE_MILLION = 5000000;

const FIGURE_PROPS = {
  width: "48%",
  captionProps: {
    colored: "brandDark"
  }
}

const BidVolume = () => {
  const { data } = useStaticQuery(BidVolumeQuery);

  const bidsPerDayChartRef = React.useRef();
  const monthlyAuctionBidVolumeChartRef = React.useRef();

  React.useLayoutEffect(() => {
    if (
      !data ||
      !monthlyAuctionBidVolumeChartRef ||
      !monthlyAuctionBidVolumeChartRef.current
    ) {
      return;
    }

    const formattedData = data.monthlyAuctionBidVolumeAllTime.map((dataPoint, i) => ({
      hns: parseInt(dataPoint.value),
      date: dataPoint.date,
    }));
    const maxMonthAuctionBidVolume = formattedData.reduce((max, current) => {
      if (current.hns > max) return current.hns;
      return max;
    }, 0);

    const chart = new Chart({
      height: 350,
      autoFit: true,
      container: monthlyAuctionBidVolumeChartRef.current,
      appendPadding: [0, 0, 4, 0]
    });
    chart.data(formattedData);
    chart.axis('hns', {
      ...CHART_AXIS_BASE_CONFIG_LIGHT,
      line: null,
      label: {
        formatter: abbreviatedLocaleString,
        ...CHART_AXIS_BASE_CONFIG_LIGHT.label,
      }
    });
    chart.axis('date', {
      ...CHART_AXIS_BASE_CONFIG_LIGHT,
      grid: null,
      label: {
        formatter: (originalValue, _, index) => {
          if (index === 0 || index === formattedData.length - 1) return dateMmmYy(originalValue);
          return ''
        },
        ...CHART_AXIS_BASE_CONFIG_LIGHT.label,
      }
    });
    chart.scale('hns', {
      min: 0,
      max: Math.ceil(maxMonthAuctionBidVolume / FIVE_MILLION) * FIVE_MILLION,
      tickInterval: FIVE_MILLION,
    });
    chart.scale('date', {
      tickCount: formattedData.length,
    });
    chart.theme({ columnWidthRatio: 0.8 });
    chart.tooltip({
      title: (originalValue) => dateMmmYy(originalValue, true),
      customItems: (items) => items.map(formatTooltipItem),
      showMarkers: false,
    });
    chart.interaction('brush');
    chart.interval().position('date*hns').color(theme.color.nbPrimary);
    chart.render();

    return () => chart.destroy();
  }, [data, monthlyAuctionBidVolumeChartRef]);
  
  React.useLayoutEffect(() => {
    if (
      !data ||
      !bidsPerDayChartRef ||
      !bidsPerDayChartRef.current
    ) {
      return;
    }

    const formattedData = data.bidsLast30Days.map((dataPoint, i) => ({
      date: dataPoint.date,
      count: parseInt(dataPoint.value),
    }));
    const maxBidsPerDay = data.bidsLast30Days.reduce((max, current) => {
      if (parseInt(current.value) > max) return parseInt(current.value);
      return max;
    }, 0);

    const chart = new Chart({
      height: 350,
      autoFit: true,
      container: bidsPerDayChartRef.current,
      appendPadding: [0, 0, 4, 0]
    });
    chart.data(formattedData);
    chart.axis('count', {
      ...CHART_AXIS_BASE_CONFIG_LIGHT,
      line: null,
      label: {
        formatter: abbreviatedLocaleString,
        ...CHART_AXIS_BASE_CONFIG_LIGHT.label,
      }
    });
    chart.axis('date', {
      ...CHART_AXIS_BASE_CONFIG_LIGHT,
      grid: null,
      label: {
        formatter: dateMmmDdYyyy,
        ...CHART_AXIS_BASE_CONFIG_LIGHT.label,
      }
    });
    chart.scale('count', {
      min: 0,
      max: Math.ceil(maxBidsPerDay / 1000) * 1000,
    });
    chart.scale('date', {
      tickCount: 2,
    });
    chart.theme({ columnWidthRatio: 0.8 });
    chart.tooltip({
      title: (originalValue) => dateMmmDdYyyy(originalValue, true),
      customItems: (items) => items.map(formatTooltipItem),
      showMarkers: false,
    });
    chart.interaction('brush');
    chart.interval().position('date*count').color(theme.color.nbPrimary);
    chart.render();

    return () => chart.destroy();
  }, [data, bidsPerDayChartRef]);

  return (
    <React.Fragment>
      <StatsPageFigure caption="Monthly auction bid volume" {...FIGURE_PROPS}>
        <Tiny
          css={{ marginBottom: theme.spacing(2) }}
          weight="medium"
          colored="grey70">
          HNS
        </Tiny>
        <ChartContainer ref={monthlyAuctionBidVolumeChartRef} heightPx={350} />
      </StatsPageFigure>
      <StatsPageFigure caption="Bids per day" {...FIGURE_PROPS}>
        <Tiny
          css={{ marginBottom: theme.spacing(2) }}
          weight="medium"
          colored="grey70">
          Count
        </Tiny>
        <ChartContainer ref={bidsPerDayChartRef} heightPx={350} />
      </StatsPageFigure>
    </React.Fragment>
  )
};

const ChartContainer = styled.div`
  height: ${({ heightPx }) => heightPx}px;
  min-height: ${({ heightPx }) => heightPx}px;
`;

const formatTooltipItem = (item) => {
  const formattedItem = Object.assign({}, item);

  if (formattedItem["name"] === "hns") formattedItem["name"] = "HNS";
  if (formattedItem["name"] === "count") formattedItem["name"] = "Count";
  formattedItem["value"] = parseInt(formattedItem["value"]).toLocaleString();

  return formattedItem;
}

export { BidVolume };