import styled from 'styled-components';

import { Regular, DisplayTiny } from '../../text';

export const Backdrop = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.spacing(43)};
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.almostBlack};
`;

export const StackText = styled.div`
  & > * + * {
    margin-top: ${({ theme }) => theme.spacing(3)};
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    ${DisplayTiny} {
      font-size: ${({ theme }) => theme.text.header.size};
      line-height: ${({ theme }) => theme.text.header.lineHeight};
    }
  }
`;

export const SectionLinks = styled.div`
  display: flex;
  justify-content: center;

  & > * + * {
    margin-left: ${({ theme }) => theme.spacing(3)};
  } 
`;

export const SectionLink = styled(Regular).attrs(props => ({
  as: 'a',
  colored: 'white'
}))`
  transition: ${({ theme }) => theme.transitions.color};

  &:hover {
    color: ${({ theme }) => theme.color.greenLight};
  }
`;